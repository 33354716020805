import './style.scss'
import RecentLeads from '../../components/dashboardComponents/RecentLeads'

const Leads = () => {
    return(
        <>
            <RecentLeads />
        </>
    )
}


export default Leads